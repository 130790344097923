<template>
  <div>
    <b-card>
      <div class="">
        <h2 class="card-title">Lịch sử nâng lương</h2>
      </div>
      <b-row>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholder="Chọn năm để tìm kiếm" @input="getListSalaryEmployee" v-model="modelSalaryEmployee.fromYear" :reduce="year => year.value" :options="lstYear" label="text"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
           <b-form-input placeholder="Nhập tên nhân viên để tìm kiếm" @keyup.enter="getListSalaryEmployee" v-model="modelSalaryEmployee.employeeName"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <div class="search-button-salary">
            <b-button @click="getListSalaryEmployee" variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span class="align-middle">Tìm kiếm</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <div class="du-lieu-bang-tang-luong">
        <b-row>
          <b-col lg="12">
            <span class="text-color-custom">Có tổng số {{totalCount}} bản ghi</span>
            <b-table
              responsive
              :items="listEmployeeSalary"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              class="mb-0">
              <template #cell(index)="data">
                <span>{{data.index + 1}}</span>
              </template>
              <template #cell(yearSchool)="data">
                <span>{{ data.item.fromYear }} - {{data.item.toYear}}</span>
              </template>
              <template #cell(hanhdong)="data">
                <b-button @click="getDetailSalary(data.item)"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-success"
                    class="btn-icon rounded-circle"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <div class="pagination-custom mt-2">
        <template>
          <b-pagination
            v-model="currentPage"
            :perPage="perPage"
            hide-goto-end-buttons
            :total-rows="rows"
          />
        </template>
      </div>
    </b-card>
    <b-modal ref="modal-detail-salary" size="lg"
     hide-footer
     hide-header
     ok-only>
      <div class="title-page-detail-salary">
        <h4>Chi tiết lịch sử nâng lương</h4>
      </div>
      <div class="content-detail-salary mt-1">
        <div class="box-control">
          <div class="label-info">
            <label>Họ và tên:</label>
          </div>
          <b-form-input readonly v-model="modelEmployeeDetail.fullName"></b-form-input>
        </div>
        <div class="box-control">
          <div class="label-info">
            <label>Mã nhân viên:</label>
          </div>
          <b-form-input readonly v-model="modelEmployeeDetail.employeeCode"></b-form-input>
        </div>
        <div class="box-control">
          <div class="label-info">
            <label>Lương:</label>
          </div>
          <b-form-input readonly v-model="modelEmployeeDetail.salary"></b-form-input>
        </div>
        <div class="box-control">
          <div class="label-info">
            <label>Phụ cấp:</label>
          </div>
          <b-form-input readonly v-model="modelEmployeeDetail.subsidize"></b-form-input>
        </div>
        <div class="box-control">
          <div class="label-info">
            <label>Tổng thu nhập:</label>
          </div>
          <b-form-input readonly v-model="modelEmployeeDetail.total"></b-form-input>
        </div>
        <div class="box-control">
          <div class="label-info">
            <label>Năm học:</label>
          </div>
          <b-form-input readonly v-model="modelEmployeeDetail.year"></b-form-input>
        </div>
        <div class="box-control">
          <div class="label-info">
            <label>Ngày bắt đầu tăng:</label>
          </div>
          <b-form-input disabled v-model="modelEmployeeDetail.startDate"></b-form-input>
        </div>
        <div class="box-control">
          <div class="label-info">
            <label>Loại tăng lương:</label>
          </div>
          <b-form-input readonly v-model="modelEmployeeDetail.type"></b-form-input>
        </div>
        <div class="box-control" v-if="modelEmployeeDetail.reasons">
          <div class="label-info">
            <label>Lý do:</label>
          </div>
          <b-form-input readonly v-model="modelEmployeeDetail.reasons"></b-form-input>
        </div>
      </div>
      <div class="footer-detail-salary mt-2">
        <b-button size="sm" @click="closeModal"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
        >
          <feather-icon
              icon="XIcon"
              class="mr-50"
          />
          <span class="align-middle">Đóng</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, VBTooltip, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BTable,
    BPagination,
    BFormGroup,
    BButton,
    BFormInput,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      modelSalaryEmployee: {
        fromYear: '',
        employeeSchoolId: '',
        employeeName: '',
      },
      totalCount: 0,
      listEmployeeSalary: [],
      lstYear: [],
      isEdit: false,
      modelEmployeeDetail: {},
      fields: [
        {
          key: 'index', label: 'stt',
        },
        {
          key: 'yearSchool', label: 'Năm học',
        },
        {
          key: 'employeeCode', label: 'Mã nhân viên',
        },
        {
          key: 'fullName', label: 'Họ và tên',
        },
        {
          key: 'startDate', label: 'Tháng/năm xếp lương',
        },
        {
          key: 'total', label: 'Tổng thu nhập',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-table-th', tdClass: 'custom-table-td',
        },
      ],
    }
  },
  created() {
    const now = new Date()
    for (let i = 1900; i < now.getFullYear(); i++) {
      this.lstYear.push({ value: i, text: `${i}` })
    }
    this.getListSalaryEmployee()
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    getListSalaryEmployee() {
      this.$crm.post('salary-school/find-by-condition', this.modelSalaryEmployee)
        .then(res => {
          this.listEmployeeSalary = res.data
          this.totalCount = this.listEmployeeSalary.length
          for (let i = 0; i < res.data.length; i++) {
            this.listEmployeeSalary[i].startDate = moment(res.data[i].startDate).format('DD-MM-YYYY')
            this.listEmployeeSalary[i].salary = new Intl.NumberFormat().format(res.data[i].salary).toString()
            this.listEmployeeSalary[i].subsidize = new Intl.NumberFormat().format(res.data[i].subsidize).toString()
            this.listEmployeeSalary[i].total = new Intl.NumberFormat().format(res.data[i].total).toString()
          }
        })
    },
    getDetailSalary(item) {
      this.$crm.get(`salary-school/find-by-id/${item.id}`)
        .then(res => {
          this.modelEmployeeDetail = res.data
          if (this.modelEmployeeDetail.type === 0) {
            this.modelEmployeeDetail.type = 'Cố định'
          } else {
            this.modelEmployeeDetail.type = 'Đặc biệt'
          }
          if (this.modelEmployeeDetail.fromYear && this.modelEmployeeDetail.toYear) {
            this.modelEmployeeDetail.fromYear = this.modelEmployeeDetail.fromYear.toString()
            this.modelEmployeeDetail.toYear = this.modelEmployeeDetail.toYear.toString()
            this.modelEmployeeDetail.year = `${this.modelEmployeeDetail.fromYear} - ${this.modelEmployeeDetail.toYear}`
          }
          if (this.modelEmployeeDetail.startDate) {
            this.modelEmployeeDetail.startDate = moment(this.modelEmployeeDetail.startDate).format('DD-MM-YYYY')
          }
          if (this.modelEmployeeDetail.salary) {
            this.modelEmployeeDetail.salary = new Intl.NumberFormat().format(this.modelEmployeeDetail.salary).toString()
          }
          if (this.modelEmployeeDetail.subsidize) {
            this.modelEmployeeDetail.subsidize = new Intl.NumberFormat().format(this.modelEmployeeDetail.subsidize).toString()
          }
          if (this.modelEmployeeDetail.total) {
            this.modelEmployeeDetail.total = new Intl.NumberFormat().format(this.modelEmployeeDetail.total).toString()
          }
          this.$refs['modal-detail-salary'].show()
        })
    },
    closeModal() {
      this.$refs['modal-detail-salary'].hide()
    },
  },
  computed: {
    rows() {
      return this.listEmployeeSalary.length
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/manger-employee';
@import '../../@core/scss/custom/manager-salary';
</style>
